<template>
  <section
    id="get-in-touch"
    class="overflow-hidden primary"
  >
    <v-row
      class="primary white--text"
    >
      <v-col
        class="mt-10  text-center "
        cols="12"
        md="12"
      >
        <base-subheading>
          Kontakt
        </base-subheading>
        <base-text>
          Wir freuen Uns auf Ihren Auftrag
        </base-text>
      </v-col>
    </v-row>
    <v-container>
      <v-row

        no-gutters
      >
        <v-col
          class="pa-5"
          cols="12"
          md="6"
        >
          <v-card
            color="primary"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2700.228410285101!2d8.581592315565308!3d47.407485809704745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aa05950d99171%3A0x25d1d0cd1c99f00b!2sUeberlandstrasse%20370%2C%208051%20Z%C3%BCrich!5e0!3m2!1sen!2sch!4v1617712524938!5m2!1sen!2sch"
              width="100%"
              height="300"
              style="border:0;"
              allowfullscreen=""
              loading="lazy"
            />
            <v-card-title>
              <p
                class="ml-3 white--text font-weight-bold"
              >
                Adresse
              </p>
            </v-card-title>

            <v-card-text>
              <div class="font-weight-bold ml-8 mb-2 white--text">
                A-Z Totalunternehmung GmbH
              </div>
              <div class="font-weight-bold ml-8 mb-2 white--text">
                Überlandstrasse 370
              </div>

              <div class="font-weight-bold ml-8 mb-2 white--text">
                8051 Zürich
              </div>
              <div class="font-weight-bold ml-8 mb-2 white--text">
                Email:
                <b><a href="mailto:info@alka-sanitaer-heizung.ch">info@a-z-totalunternehmung.ch</a></b>
              </div>
              <div class="font-weight-bold ml-8 mb-2 white--text">
                Tel:
                <b><a href="tel:+41796681646">079 668 16 46</a></b>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          class="pa-5"
          cols="12"
          md="6"
        >
          <contact-form />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  import ContactForm from '@/components/ContactForm'
  export default {
    name: 'GetInTouch',
    components: { ContactForm },
  }
</script>
<style>
.v-application a{
  font-weight:bold;
  color:white;
}
</style>
