<template>
  <v-sheet
    color="transparent"
    max-width="600"
  >
    <div v-if="!sent && !sending">
      <form id="contactform">
        <v-text-field
          v-model="form.from_name"
          color="primary"
          label="Name"
          solo
          flat
          name="from_name"
        />

        <v-text-field
          v-model="form.reply_to"
          color="primary"
          label="Email"
          solo
          flat
          name="reply_to"
        />

        <v-text-field
          color="primary"
          label="Betreff"
          solo
          flat
        />

        <v-textarea
          v-model="form.message"
          color="info"
          label="Nachricht"
          solo
          flat
          name="message"
        />

        <base-btn @click="sendEmail">
          Senden
        </base-btn>
      </form>
    </div>
    <div v-if="!sent && sending">
      Wird gesendet...
    </div>
    <div v-if="sent">
      Vielen Dank, Wir melden uns so bald wie möglich bei
      Ihnen.
    </div>
  </v-sheet>
</template>

<script>

  import emailjs from 'emailjs-com'

  export default {
    bodyClass: 'landing-page',
    props: {
      white: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return { sent: false, sending: false, form: {} }
    },
    methods: {
      sendEmail: function () {
        this.sending = true
        const self = this
        emailjs.sendForm('service_0ug2djr', 'template_9lkrcti', '#contactform', 'user_LQ8Hn6GOecn45N5MmoL0t')
          .then((result) => {
            self.sending = false
            self.sent = true
            console.log('SUCCESS!', result.status, result.text)
          }, (error) => {
            console.log('FAILED...', error)
          })
      },
    },
  }
</script>

<style scoped>
    .container{
        text-align: center;
    }

    .black{
        color:#333;
    }

    .text-box{
        border-radius:5px;
        padding-left:5px;

    }

</style>

<style>

    </style>
